
         
import Timer from './Timer';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend

} from 'chart.js';
import { Bar ,Pie}  from 'react-chartjs-2';
import React, { useState, useEffect } from 'react'
import './App.css'
import Select from 'react-select'



ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend

)


const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition
const mic = new SpeechRecognition()

mic.continuous = true
mic.interimResults = true
mic.lang = 'en-US'

function App() {
 
  const [inputValueTwo, setInputValueTwo] = useState('')
  const [inputValueThree, setInputValueThree] = useState('')
  const [inputValueFour, setInputValueFour] = useState('')
  const [inputValueFive, setInputValueFive] = useState('')
  const [inputValueSix, setInputValueSix] = useState('')
  const [inputValueSeven, setInputValueSeven] = useState('')
  const [isListening, setIsListening] = useState(false)
  const [note, setNote] = useState(null)
  const [savedNotes, setSavedNotes] = useState([])

  


  
  const a = "3____________________________15_______________45_______________________135"
  const b = "3____________________________30_______________90_______________________270"
  const c = "4____________________________30_______________68_______________________272"
  const d = "5____________________________30_______________54_______________________270"
  const e = "6____________________________30_______________45_______________________270"
  const f = "5____________________________45_______________81_______________________405"
  const g = "6____________________________45_______________68_______________________408"
  

  const options = [
    { value: "one", label: a},
    { value: "two", label: b},
    { value: "three", label: c},
    { value: "four", label: d},
    { value: "five", label: e},
    { value: "six", label: f},
    { value: "seven", label: g},
   
  ]

  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [selectedValue, setSelectedValue] = useState();

  
  

  const handleOptions = (selectedOption) => {
    setSelectedOption(selectedOption);
  }
 
  const target = []
  for (let i = 1; i <= 50; i++) {
    target.push({ value: i, label: i })
  }

  const targetTotal = []
  for (let i = 1; i <= 500; i++) {
    targetTotal.push({ value: i, label: i })
  }

  useEffect(() => {
    handleListen()
  }, [isListening])

  const handleListen = () => {
    if (isListening) {
      mic.start()
      mic.onend = () => {
        console.log('continue..')
        mic.start()
      }
    } else {
      mic.stop()
      mic.onend = () => {
        console.log('Stopped Mic on Click')
      }
    }
    mic.onstart = () => {
      console.log('Mics on')
    }

    mic.onresult = event => {
      const transcript = Array.from(event.results)
        .map(result => result[0])
        .map(result => result.transcript)
        .join('')
      console.log(transcript)
      setNote(transcript)
      mic.onerror = event => {
        console.log(event.error)
      }
    }
  }
 
  const handleSaveNote = () => {
    setSavedNotes([...savedNotes, note.toLowerCase()])
    setNote('')
  }

  useEffect(() => {
    const interval = setInterval(() => {
        document.querySelector("#save-button").click();
    }, 60000);
    return () => clearInterval(interval);
}, []);

 let wordCountTwo = 0;
 savedNotes.map(note => {
    wordCountTwo += note.split(" ").filter(word => word === inputValueTwo).length;
 });

 let wordCountThree = 0;
 savedNotes.map(note => {
    wordCountThree += note.split(" ").filter(word => word === inputValueThree).length;
 });

 let wordCountFour = 0;
 savedNotes.map(note => {
    wordCountFour += note.split(" ").filter(word => word === inputValueFour).length;
 });

 let wordCountFive = 0;
 savedNotes.map(note => {
    wordCountFive += note.split(" ").filter(word => word === inputValueFive).length;
 });

 let wordCountSix = 0;
 savedNotes.map(note => {
    wordCountSix += note.split(" ").filter(word => word === inputValueSix).length;
 });

 let wordCountSeven = 0;
 savedNotes.map(note => {
    wordCountSeven += note.split(" ").filter(word => word === inputValueSeven).length;
 });


  const data = {
    labels: [inputValueSeven, inputValueTwo, inputValueThree, inputValueFour,inputValueFive,inputValueSix],
    datasets: [
      {
        label: '369',
        data: [wordCountSeven,wordCountTwo,wordCountThree,wordCountFour,wordCountFive,wordCountSix],
        backgroundColor: 'aqua',
        borderColor: 'black',
        borderWidth: 1,
  
      }
    ]
  
  }

const optionsb = {

  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          precision: 0,
          tickInterval: 1
        },
      },
    ],
  },
}

const lists = {
  one: [3, 4, 5],
  two: [6, 7, 8, 9],
  three: [10, 11, 12, 13, 14],
};

const total = wordCountTwo + wordCountThree + wordCountFour + wordCountFive + wordCountSix + wordCountSeven



  return (
    <>
      <div className='heading-container'>
      <h1 className='first'> Wombat Words</h1>
    
      </div>
      <div >
       
        <Select options={options} onChange={handleOptions} className='input-box' placeholder="number of words____________Duration_________Dose Per Word_____________Target Total" />

       
      
      


      </div>
      <div className='firstTwo'>
      
      <div className='inputsifs'>
      
      {selectedOption.value === "one" && (
        <>
           <input className='words' placeholder='first word' value={inputValueSeven} onChange={e => setInputValueSeven(e.target.value)} />
          <input className='words' placeholder='second word' value={inputValueTwo} onChange={e => setInputValueTwo(e.target.value)} />
          <input className='words' placeholder='third word' value={inputValueThree} onChange={e => setInputValueThree(e.target.value)} />
          
        </>
      )}
       
      {selectedOption.value === "two" && (
        <>
          <input className='words' placeholder='first word' value={inputValueSeven} onChange={e => setInputValueSeven(e.target.value)} />
          <input className='words' placeholder='second word' value={inputValueTwo} onChange={e => setInputValueTwo(e.target.value)} />
          <input className='words' placeholder='third word' value={inputValueThree} onChange={e => setInputValueThree(e.target.value)} />
          
        </>
      )}
       {selectedOption.value === "three" && (

        <>
         <input className='words' placeholder='first word' value={inputValueSeven} onChange={e => setInputValueSeven(e.target.value)} />
         <input className='words' placeholder='second word' value={inputValueTwo} onChange={e => setInputValueTwo(e.target.value)} />
         <input className='words' placeholder='third word' value={inputValueThree} onChange={e => setInputValueThree(e.target.value)} />
         <input className='words' placeholder='fourth word' value={inputValueFour} onChange={e => setInputValueFour(e.target.value)} />
        </>
       
      )}
        {selectedOption.value === "four" && (
         <>
         <input className='words' placeholder='first word' value={inputValueSeven} onChange={e => setInputValueSeven(e.target.value)} />
         <input className='words' placeholder='second word' value={inputValueTwo} onChange={e => setInputValueTwo(e.target.value)} />
         <input className='words' placeholder='third word' value={inputValueThree} onChange={e => setInputValueThree(e.target.value)} />
         <input className='words' placeholder='fourth word' value={inputValueFour} onChange={e => setInputValueFour(e.target.value)} />
         <input className='words' placeholder='fifth word' value={inputValueFive} onChange={e => setInputValueFive(e.target.value)} />
       </>
      )}
         {selectedOption.value === "five" && (
          <>
          <input className='words' placeholder='first word' value={inputValueSeven} onChange={e => setInputValueSeven(e.target.value)} />
          <input className='words' placeholder='second word' value={inputValueTwo} onChange={e => setInputValueTwo(e.target.value)} />
          <input className='words' placeholder='third word' value={inputValueThree} onChange={e => setInputValueThree(e.target.value)} />
          <input className='words' placeholder='fourth word' value={inputValueFour} onChange={e => setInputValueFour(e.target.value)} />
          <input className='words' placeholder='fifth word' value={inputValueFive} onChange={e => setInputValueFive(e.target.value)} />
          <input className='words' placeholder='sixth word' value={inputValueSix} onChange={e => setInputValueSix(e.target.value)} />
          
        </>
      )}
         {selectedOption.value === "six" && (
         <>
         <input className='words' placeholder='first word' value={inputValueSeven} onChange={e => setInputValueSeven(e.target.value)} />
         <input className='words' placeholder='second word' value={inputValueTwo} onChange={e => setInputValueTwo(e.target.value)} />
         <input className='words' placeholder='third word' value={inputValueThree} onChange={e => setInputValueThree(e.target.value)} />
         <input className='words' placeholder='fourth word' value={inputValueFour} onChange={e => setInputValueFour(e.target.value)} />
         <input className='words' placeholder='fifth word' value={inputValueFive} onChange={e => setInputValueFive(e.target.value)} />
       </>
      )}
       {selectedOption.value === "seven" && (
         <>
         <input className='words' placeholder='first word' value={inputValueSeven} onChange={e => setInputValueSeven(e.target.value)} />
         <input className='words' placeholder='second word' value={inputValueTwo} onChange={e => setInputValueTwo(e.target.value)} />
         <input className='words' placeholder='third word' value={inputValueThree} onChange={e => setInputValueThree(e.target.value)} />
         <input className='words' placeholder='fourth word' value={inputValueFour} onChange={e => setInputValueFour(e.target.value)} />
         <input className='words' placeholder='fifth word' value={inputValueFive} onChange={e => setInputValueFive(e.target.value)} />
         <input className='words' placeholder='sixth word' value={inputValueSix} onChange={e => setInputValueSix(e.target.value)} />
         
       </>
      )}
       </div> 

       <div className='inputsifs'>
       
        <h1 className='headingRecording'>Duration Timer</h1>

        
        <div className='tk'>
        < Timer />

        </div>
     
      
      </div>

      <div className='recordings'>
      
        <h2 className='headingRecording'>Recording</h2>

      
        <div className='phakathi'>
        {isListening ? <span className='mic'>🎙️</span> : <span>⏺️🎤</span>}

        </div>
        <div className='transcript'>
        <button className='save-btn' id="save-button"  onClick={handleSaveNote} disabled={!note}>
            Save Transcript
          </button>

        </div>
  
          
          <button className='save-btn' onClick={() => setIsListening(prevState => !prevState)}>
            Start/Stop
          </button>     
        </div>

      </div>

     <div style={{ width: '100%', height: 'auto', maxWidth: '500px', margin: '0 auto' }}>
     <Bar
      data  = {data}
      options= {optionsb}>

      </Bar>
     
     </div>
   
    
          

          <div className='nastyc'>
          <h1>Target total</h1>
          <h1 className='pieChart'>{total}</h1>
          {selectedOption.value === "one" && <span><h1>135</h1></span>}
          {selectedOption.value === "two" && <span><h1>270</h1></span>}
          {selectedOption.value === "three" && <span><h1>272</h1></span>}
          {selectedOption.value === "four" && <span><h1>270</h1></span>}
          {selectedOption.value === "five" && <span><h1>270</h1></span>}
          {selectedOption.value === "six" && <span><h1>405</h1></span>}
          {selectedOption.value === "seven" && <span><h1>408</h1></span>}

          

      </div>
      <div className='notes'>
      {savedNotes}

      <div>
   
  </div>  
     </div> 
    </>
  )
}

export default App  
